<template>
	<div class="mb-5 shadow-sm">
		<table id="dupes" style="width:100%">
			<tr>
				<th width="7%">Keep</th>
				<th width="7%">Previously Screened</th>
				<th v-for="column of columns" :width="column.width" :key="column.key">
					{{ column.name }}
				</th>
				<th width="7%">Duplicate</th>
			</tr>
			<tbody v-for="(dupe, j) of dupeList" @dblclick="editReference(dupe.id)" :key="j">
				<tr>
					<td>
						<b-form-checkbox
							v-model="dupe.keep"
							switch
							:disabled="isScreened"
							@change="markAsKeep(group, index, j)"
						>
							<span v-if="dupe.keep">Keep</span>
							<span v-else>Discard</span>
						</b-form-checkbox>
					</td>
					<td>{{ dupe.isScreened ? "Yes" : "No" }}</td>
					<td v-for="column of columns" :key="column.key">
						<template v-if="column.key == 'authors'">
							{{ formatAuthors(refs[dupe.id][column.key]) }}
						</template>
						<template v-else-if="column.key == 'abstract'">
							<b-button size="sm" v-if="!showDetails[j]" @click.stop="$set(showDetails, j, true)">Show Abstract</b-button>
							<b-button size="sm" v-if="showDetails[j]" @click.stop="$set(showDetails, j, false)">Hide Abstract</b-button>
						</template>
						<template v-else>
							{{ refs[dupe.id][column.key] }}
						</template>
					</td>
					<td><b-button variant="primary" @click.stop="$emit('not-dupe', j)" size="sm">Not Dupe</b-button></td>
				</tr>
				<tr v-if="showDetails[j]">
					<td colspan="100%">
						<b-card class="bg-secondary text-light">
							{{ refs[dupe.id].abstract }}
						</b-card>
					</td>
				</tr>
			</tbody>
			<tr class="split-row">
				<td></td>
				<td></td>
				<td v-for="column of columns" :key="column.key"></td>
				<td><b-button variant="success" size="sm" @click="splitGroup">Split Group</b-button></td>
			</tr>
		</table>
		<!-- SPLIT MODAL -->
		<b-modal @ok="$emit('split-group', selected)" hide-header size="xl" :ref="`split-modal-${ absoluteIndex }`">
			Select all the references you wish to move to a new group
			<table class="split-table" id="dupes" style="width:100%">
				<tr>
					<th width="5%">Split</th>
					<th v-for="column of columns" :width="column.width" :key="column.key">
					{{ column.name }}
				</th>
				</tr>
				<tbody v-for="(dupe, j) of dupeList" @click="select(j)" :key="j">
					<tr>
						<td>
							<input type="checkbox" v-bind:value="j" v-model="selected" />
						</td>
						<td v-for="column of columns" :key="column.key">
							<template v-if="column.key == 'authors'">
								{{ formatAuthors(refs[dupe.id][column.key]) }}
							</template>
							<template v-else-if="column.key == 'abstract'">
								<b-button size="sm" v-if="!showDetails[j]" @click.stop="$set(showDetails, j, true)">Show Abstract</b-button>
								<b-button size="sm" v-if="showDetails[j]" @click.stop="$set(showDetails, j, false)">Hide Abstract</b-button>
							</template>
							<template v-else>
								{{ refs[dupe.id][column.key] }}
							</template>
						</td>
					</tr>
					<tr v-if="showDetails[j]">
					<td colspan="100%">
						<b-card class="bg-secondary text-light">
							{{ refs[dupe.id].abstract }}
						</b-card>
					</td>
				</tr>
				</tbody>
			</table>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatMixin from '../mixins/formatMixin';

export default {
	name: "DupesAreaTable",
	props: {
		group: Number, // Index of the category (e.g Non-dupes = 3)
		absoluteIndex: Number, // Absolute index (e.g. page two 1st ref = 0)
		index: Number, // Index of dupeList within group
		dupeList: Array, // Dupelist array
	},
	mixins: [
		formatMixin
	],
	data() {
		return {
			showModal: false,
			selected: [],
			showDetails: []
		}
	},
	mounted() {
		this.showDetails = new Array(this.dupeList.length).fill(false);
	},
	computed: {
		...mapGetters({
			refs: "references/getRefs",
			columns: "tableFields/getFieldsActive"
		}),
		isScreened() {
			return this.dupeList.some(dupe => dupe.isScreened);
		}
	},
	methods: {
		markAsKeep(listIndex, i, j) {
			// Mark ref as the one to keep
			this.$store.commit('duplicates/markAsKeep', { listIndex, i, j });
		},
		clearSelection() {
			if(document.selection && document.selection.empty) {
					document.selection.empty();
			} else if(window.getSelection) {
					var sel = window.getSelection();
					sel.removeAllRanges();
			}
		},
		editReference(id) {
			this.clearSelection();
			if (id) {
				this.$root.$emit('show-edit', id);
			} else {
				console.log("No reference found");
			}
		},
		select(j) {
			const index = this.selected.indexOf(j);
			// If exists, remove
			if (index !== -1) {
				this.selected.splice(index, 1);
			}
			// Else push to array
			else {
				this.selected.push(j);
			}
		},
		splitGroup() {
			this.showModal = true;
			this.$refs[`split-modal-${ this.absoluteIndex }`].show();
		}
	}
}
</script>

<style scoped lang="scss">
@import "@/theme.scss";
#dupes {
	border-collapse: collapse;
	width: 100%;
	background-color: ""
}

#dupes td, #dupes th {
	border: 1px solid $gray-300;
	padding: 8px;
}

#dupes tr {
	background-color: $gray-100;
}

#dupes th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: map-get($theme-colors, primary);
	color: $gray-100;
}

#dupes .split-row {
	background-color: $gray-300;
}

#dupes tr:hover {
	background-color: $gray-200 !important;
}
</style>
<template>
	<div v-if="dupeList.length" class="shadow">
		<div id="dupes" style="width:100%">
			<div class="tr" :style="headerStyle">
				<span
					v-for="column in columns"
					class="th text-truncate"
					:key="column.key"
					:style="column.style"
				>
					{{ column.label }}&nbsp;
				</span>
			</div>
			<RecycleScroller
				class="scroller"
				:items="dupeList"
				:item-size="40"
			>

				<template v-slot="{item: dupe, index: j }">
					<div class="tr" @dblclick="editReference(dupe.id)" :key="j">
						<span
							v-for="column in columns"
							class="td text-truncate"
							:key="column.key"
							:style="column.style"
						>
							<template v-if="column.key == 'authors'">
								{{ formatAuthors(refs[dupe.id][column.key]) }}
							</template>
							<template v-else-if="column.key == 'isScreened'">
								{{ dupe[column.key] ? "Yes" : "No" }}
							</template>
							<template v-else>
								{{ refs[dupe.id][column.key] }}
							</template>&nbsp;
						</span>
					</div>
				</template>
			</RecycleScroller>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatMixin from '../mixins/formatMixin';
import columnsMixin from '../mixins/columnsMixin';

import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export default {
	name: "DupesAreaVirtualTable",
	props: {
		index: Number, // Index of dupeList within group
		dupeList: Array, // Dupelist array
		display: Boolean // Whether the table is shown
	},
	components: {
		RecycleScroller
	},
	mixins: [
		formatMixin,
		columnsMixin
	],
	watch: {
		display: async function() {
			// Initialize columns when display changes
			setTimeout(this.initializeResizableColumns, 100);
			setTimeout(this.setScrollbarWidth, 100);
		},
	},
	data() {
		return {
			showModal: false,
			selected: [],
			scrollbarWidth: this.getScrollbarWidth()
		}
	},
	computed: {
		...mapGetters({
			refs: "references/getRefs"
		}),
		headerStyle() {
			return {
				width: `calc(100% - ${this.scrollbarWidth}px) !important`
			}
		}
	},
	methods: {
		getScrollbarWidth() {
			// Creating invisible container
			const outer = document.createElement('div');
			outer.style.visibility = 'hidden';
			outer.style.overflow = 'scroll'; // forcing scrollbar to appear
			outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
			document.body.appendChild(outer);
			// Creating inner element and placing it in the container
			const inner = document.createElement('div');
			outer.appendChild(inner);
			// Calculating difference between container's full width and the child width
			const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
			// Removing temporary elements from the DOM
			outer.parentNode.removeChild(outer);
			return scrollbarWidth;
		},
		setScrollbarWidth() {
			const scroller = document.getElementsByClassName("scroller")[0];
			// If Scrollbar exists
			if (scroller && scroller.scrollHeight > scroller.clientHeight) {
				this.scrollbarWidth = this.getScrollbarWidth();
			} else {
				// No scrollbar exists
				this.scrollbarWidth = 0;
			}
		},
		clearSelection() {
			if(document.selection && document.selection.empty) {
					document.selection.empty();
			} else if(window.getSelection) {
					var sel = window.getSelection();
					sel.removeAllRanges();
			}
		},
		editReference(id) {
			this.clearSelection();
			if (id) {
				this.$root.$emit('show-edit', id);
			} else {
				console.log("No reference found");
			}
		},
		select(j) {
			const index = this.selected.indexOf(j);
			// If exists, remove
			if (index !== -1) {
				this.selected.splice(index, 1);
			}
			// Else push to array
			else {
				this.selected.push(j);
			}
		},
		splitGroup() {
			this.showModal = true;
			this.$refs[`split-modal-${ this.index }`].show();
		}
	},
}
</script>

<style scoped lang="scss">
@import "@/theme.scss";
.scroller {
	max-height: calc(100vh - 365px);
}
#dupes .header{
	width: calc(100% - 15px) !important;
}
#dupes .tr {
	width: 100%;
	background-color: #f2f2f2;
	height: 40px;
}
#dupes .tr:hover {
	background-color: #ddd !important;
}
#dupes .td, #dupes .th {
	display: inline-block;
	border: 1px solid #ddd;
	padding: 8px;
}
#dupes .th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: map-get($theme-colors, primary);
	color: white;
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import { createSyncPlugin } from '@iebh/vuex-tera-sync';

import metadata from './modules/metadata'
import references from './modules/references'
import duplicates from './modules/duplicates'
import tableFields from './modules/tableFields'

Vue.use(Vuex)

// Create TERA sync plugin with configuration
export const teraSyncPlugin = createSyncPlugin('deduplicator', false, {
	debounceMs: 100
})

export default new Vuex.Store({
	modules: {
		metadata,
		references,
		duplicates,
		tableFields
	},
	plugins: [teraSyncPlugin],
	actions: {
		resetStore({ commit }) {
			commit('metadata/reset');
			commit('references/reset');
			commit('duplicates/reset');
			commit('tableFields/reset');
		}
	}
})
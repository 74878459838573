export default {
	data() {
		return {
			cols: undefined,
			thIndex: undefined,
			startOffset: undefined,
			columns: [
				{
					label: "Screened",
					key: "isScreened",
					style: "width: 6%"
				},
				{
					label: "Title",
					key: "title",
					style: "width: 37%"
				},
				{
					label: "Authors",
					key: "authors",
					style: "width: 19%"
				},
				{
					label: "Year",
					key: "year",
					style: "width: 6%"
				},
				{
					label: "Journal",
					key: "journal",
					style: "width: 19%"
				},
				{
					label: "Volume",
					key: "volume",
					style: "width: 6%"
				},
				{
					label: "Pages",
					key: "pages",
					style: "width: 7%"
				}
			]
		}
	},
	mounted() {
		this.$nextTick(this.initializeEventListeners);
	},
	beforeDestroy() {
		this.removeEventListeners();
	},
	methods: {
		onMouseMove(e) {
			if (!isNaN(this.thIndex)) {
				const i = this.thIndex;
				const thElm = this.cols[i];
				const tableRow = thElm.parentNode;
				const nextColumnOldPercent = parseInt(thElm.nextSibling.style.width)
				// Save old width and calculate new widths
				const oldWidthPercent = parseInt(thElm.style.width);
				const newWidth = this.startOffset + e.pageX;
				const newWidthPercent = Math.round((newWidth / tableRow.offsetWidth) * 100);
				const nextColumnNewPercent = nextColumnOldPercent + (oldWidthPercent - newWidthPercent);
				// If greater than minimum width, set new width
				if (newWidthPercent > 5 && nextColumnNewPercent > 5) {
					this.$set(this.columns[i], "style", `width: ${newWidthPercent}%`);
					this.$set(this.columns[i+1], "style", `width: ${nextColumnNewPercent}%`);
				}

			}
		},
		onMouseUp() {
			if (!isNaN(this.thIndex)) {
				// Reset variables
				this.thIndex = undefined;
				this.$nextTick(this.initializeResizableColumns);
			}
		},
		removeEventListeners() {
			// Remove existing
			window.addEventListener('resize', this.initializeResizableColumns);
			document.removeEventListener('mousemove', this.onMouseMove, { passive: true });
			document.removeEventListener('mouseup', this.onMouseUp);
		},
		initializeEventListeners() {
			// Add new
			window.addEventListener('resize', this.initializeResizableColumns);
			document.addEventListener('mousemove', this.onMouseMove, { passive: true });
			document.addEventListener('mouseup', this.onMouseUp);
		},
		initializeResizableColumns() {
			var zindex = 99;
			// Cleanup existing divs
			[...document.getElementsByClassName("resize")].map(n => n && n.remove());
			// Add draggable divs
			this.cols = document.querySelectorAll("#dupes .th");
			// Dont include last column
			for (let i = 0; i < this.cols.length - 1; ++i) {
				// TODO: Only add resize grip if not last column
				var grip = document.createElement('div');
				grip.className = "resize"
				grip.innerHTML = "&nbsp;";
				grip.style.top = 0;
				grip.style.bottom = 0;
				grip.style.left = this.cols[i].offsetLeft + this.cols[i].offsetWidth - 5 + "px";
				grip.style.width = '10px';
				grip.style.position = 'absolute';
				this.cols[i].style.zIndex = zindex;
				zindex -= 1;
				grip.style.cursor = 'col-resize';
				grip.addEventListener('mousedown', e => {
					e.preventDefault();
					e.stopPropagation();
					e.stopImmediatePropagation();
					// Set element, starting offset position and relative nodes
					this.thIndex = i;
					this.startOffset = this.cols[i].offsetWidth - e.pageX;
					return false;
				});
				this.cols[i].appendChild(grip);
			}
		}
	}
}
<template>
  <div class="list-container">
    <div class="m-2">
      <b-button block @click="reset">Reset</b-button>
      <b>Display (in order):</b>
      <draggable class="list-group" :list="fields" group="fields">
        <div
          class="list-group-item"
          v-for="element in fields"
          :key="element.key"
        >
          {{ element.name }}
        </div>
      </draggable>
      <hr />
      <b>Don't Display:</b>
      <draggable class="list-group" :list="inactiveFields" group="fields">
        <div
          class="list-group-item inactive-fields"
          v-for="element in inactiveFields"
          :key="element.key"
        >
          {{ element.name }}
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: Array
  },
  components: {
    draggable
  },
  computed: {
    ...mapGetters('tableFields', ['getFieldsActive', 'getFieldsInactive']),
    fields: {
      set(fields) {
        this.$store.commit('tableFields/setFieldsActive', fields);
      },
      get() {
        return this.getFieldsActive;
      }
    },
    inactiveFields: {
      set(inactiveFields) {
        this.$store.commit('tableFields/setFieldsInactive', inactiveFields);
      },
      get() {
        return this.getFieldsInactive;
      }
    }
  },
  methods: {
    reset() {
      this.$store.commit('tableFields/reset');
    }
  }
};
</script>

<style scoped>
.list-container {
  max-height: 500px;
  overflow-y: auto;
}
.inactive-fields {
  background-color: rgb(226, 226, 226);
}
</style>